<template>
  <div
    ref="view"
    class="m-chart d-flex justify-space-between"
    :class="[{ 'flex-column': type=='column', 'animate pulse': loading, full }, type, labels, (labels=='end' ? `flex-${type=='bar' ? 'row' : 'column'}-reverse` : '')]"
    :style="[{ minHeight, minWidth }, grid&&!scroller ? type=='bar' ? { 'padding-top': (parseInt(size)*2)+'px !important' } : { 'padding-left': (parseInt(size)*2)+'px !important' } : {} ]"
    v-resize="onResize"
    @touchstart="hover=true"
    @touchend="toggleLabels(false, true)"
    @mouseenter="hover=true"
    @mouseleave="toggleLabels(false, true)"
    @mousedown="hover=true"
    @mouseup="toggleLabels(false, true)"
  >
    <div
      v-if="labels!==false"
      ref="labels"
      class="m-chart--labels flex-shrink-0"
      :class="[{ 'flex-column': type=='bar', 'short': shortLabels }]"
    >
      <v-slide-y-transition>
        <div 
          v-if="showLabels"
          :class="{ 'pr-2': type=='bar', 'pb-2': type=='column' }"
          class="m-chart--labels-container d-flex justify-space-around"
        >
          <div 
            v-for="(d, l) in views.chart.data"
            :key="`label-${l}`"
            class="m-chart--label grey--text text--lighten-3"
            :style="type=='column' ? { width: size+'px' } : { height: size+'px' }"
          >
            <span 
              class="label d-inline-block text-caption text-no-wrap"
              :style="type=='column' ? { width: size+'px' } : { height: size+'px' }"
            >
              {{ d.label }}
            </span>
          </div>
        </div>
      </v-slide-y-transition>
    </div>

    <div
      ref="chart"
      class="m-chart--view d-flex justify-space-around flex-grow-1"
      :class="{ 'flex-column': type=='bar' }"
      :style="{ maxHeight, maxWidth }"
    >
      <div 
        v-for="(bar, b) in views.chart.data"
        :key="`bar-${bar.key}`"
        :ref="`bar-${bar.key}`"
        class="m-chart--bar d-flex align-center"
        :class="[{ 'flex-column': type=='column', 'highlight': bar.highlight }, 'justify-'+(position)]"
        :style="type=='column' ? { 'padding': `0 ${gutter/2}px`} : { 'padding': `${gutter/2}px 0` }"
        @click.stop="toggleTooltip(bar)"
      >
        <div 
          v-for="(value, v) in bar.values"
          :key="`bar-${bar.key}-${v}`"
          :ref="`bar-${bar.key}-${v}`"
          class="m-chart--stack"
          :class="[(data==null ? 'grey' : Array.isArray(color) ? v in color ? color[v] : 'projection' : color)]"
          :style="[bar.style, type=='column' ? { height: ((value/views.grid.max)*100) + '%' } : { width: ((value/views.grid.max)*100) + '%' }, Array.isArray(color) && v in color ? { backgroundColor: color[v]} : { backgroundColor: color }, { borderRadius: v==bar.rounded ? roundness + 'px ' + roundness + 'px 0 0' : 0 }]"
          @click.stop="toggleTooltip(bar, v)"
        />
        <slot 
          v-if="bar.empty&&!bar.pending"
          name="empty" 
        />
      </div>
      <v-scroll-x-transition>
        <label
          v-show="views.tooltip.toggle"
          :class="[!!views.tooltip.color ? views.tooltip.color : 'grey']"
          class="m-chart--tooltip text-overline text-center overflow-visible font-weight-bold black--text pa-1 elevation-2 rounded"
          :style="{ 'min-width': (gutter + size) + 'px', ...views.tooltip.position }"
        >
          {{ views.tooltip.value | formatValue(format, views.tooltip.target.index) }}
          <v-icon
            :color="!!views.tooltip.color ? views.tooltip.color : 'grey'"
            :class="[position]"
            class="tip"
          >
            {{ icons.tip }}
          </v-icon>
        </label>
      </v-scroll-x-transition>

      <portal 
        :disabled="scroller===null"
        :to="scroller"
        class="m-chart--grid-container"
      >
        <div
          v-if="grid"
          class="m-chart--grid d-flex justify-space-between"
          :class="[{ full }, type, `flex-${type=='bar' ? 'row' : 'column'}${position=='end' ? '-reverse' : ''}` ]"
          :style="{ width: views.chart.width+'px', height: views.chart.height+'px', ['margin-'+ (type=='column' ? position=='end' ? 'top' : 'bottom' : position=='end' ? 'left' : 'right')]: views.grid.spacing+'px'}"
        >
          <div 
            v-for="(label, s) in views.grid.steps"
            :key="`grid-${s}`"
            :class="[`align-${position}`]"
            class="m-chart--step d-flex grey--text"
          >
            <span 
              class="label d-inline-block text-no-wrap text-caption"
              :style="{ lineHeight: size+'px' }"
            >{{ label }}{{ unit }}</span>
          </div>
        </div>
      </portal>
    </div>

  </div>
</template>

<style>

  .m-chart {
    position: relative;
    will-change: opacity, transform;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
    /* transform: translateY(100%); */
  }
  .m-chart.loading {
    transform: translateY(0);
  }
  .m-chart.column .m-chart--labels {
    min-height: 24px;
    min-width: 24px;
  }
  .m-chart.column .m-chart--labels:not(.short) .m-chart--label {
    writing-mode: vertical-rl;
  }
  .m-chart .m-chart--label, .m-chart .m-chart--label .label {
    min-height: 12px;
    line-height: 12px;
    transform: translateY(-1px);
  }
  .m-chart .m-chart--view {
    /* position: relative; */
    /* border-left: 1px solid var(--v-grey-darken3) !important; */
  }
  .m-chart.column .m-chart--view {
    /* border-bottom: 1px solid var(--v-grey-darken3) !important; */
  }
  .m-chart .m-chart--view, .m-chart .m-chart--bar {
    width: 100%;
  }
  .m-chart.column .m-chart--view, .m-chart.column .m-chart--bar {
    min-height: 100%;
  }
  .m-chart .m-chart--bar {
    width: 100%;
  }
  .m-chart .m-chart--bar.highlight {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 25%, rgba(255, 255, 255, 0.12) 100%);
  }
  /* .m-chart .m-chart--bar:not(:last-child) {
    margin-bottom: 2px;
  }
  .m-chart.column .m-chart--bar:not(:last-child) {
    margin-right: 2px;
  } */
  .m-chart .m-chart--stack {
    position: relative;
    will-change: opacity, height, width;
    transition: opacity 250ms ease-in-out, height 500ms ease-in-out, width 500ms ease-in-out;
  }
  .m-chart--stack.projection {
    border: 2px dotted var(--v-grey-base);
    border-bottom: none;
  }
  /* .m-chart .m-chart--stack + :not(.void) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  } */
  .m-chart .m-chart--tooltip {
    position: absolute;
    right: 0;
    top: 50%;
    min-width: 32px;
    transform: translate(-125%, -50%);
    line-height: 1rem;
    letter-spacing: .05em !important;
  }
  .m-chart .m-chart--tooltip span {
    text-align: center;
  }
  .m-chart .m-chart--tooltip .tip {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .m-chart .m-chart--tooltip .tip.end {
    top: 50%;
    right: 0;
    left: auto;
    transform: translate(50%, -50%) rotate(-90deg);
  }
  .m-chart.column .m-chart--tooltip .tip {
    top: 0;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-180deg);
  }
  .m-chart.column .m-chart--tooltip .tip.end {
    bottom: 0;
    top: auto;
    transform: translate(-50%, 50%);
  }
  .m-chart.column .m-chart--tooltip {
    right: auto;
    top: 0;
    left: 50%;
    transform: translate(-50%, -125%);
  }

  .m-chart--grid-container {
    pointer-events: none;
    z-index: 1;
  }
  .m-chart--grid-container .m-chart--grid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .m-chart--grid-container .m-chart--grid .m-chart--step:first-child, .m-chart--grid-container .m-chart--grid .m-chart--step:last-child {
    visibility: hidden;
  }
  .m-chart--grid-container .m-chart--grid .m-chart--step {
    position: relative;
    /* border-left: 1px solid var(--v-grey-darken3); */
    will-change: opacity, height, width;
    transition: opacity 250ms ease-in-out
  }
  .m-chart--grid-container .m-chart--grid.column .m-chart--step {
    height: 0 !important;
    /* border-top: 1px solid var(--v-grey-darken3);  */
  }
  .m-chart--grid-container .m-chart--grid .m-chart--step .label {
    transform: translate(50%, 0);
  }
  .m-chart--grid-container .m-chart--grid.column .m-chart--step .label {
    transform: translate(0, 50%);
  }
  
  .m-chart--grid-container .m-chart--grid:not(.full) .m-chart--step .label {
    transform: translate(-50%, -100%);
  }
  .m-chart--grid-container .m-chart--grid.column:not(.full) .m-chart--step .label {
    transform: translate(-100%, -50%);
  }

</style>

<script>
  import { 
    mdiMenuDown,
  } from '@mdi/js';
  import numeral from 'numeral'

  export default {
    props: {
      type: {
        type: String,
        default: 'bar',
      },
      data: {
        type: Array,
        default: () => {
          return null
        }
      },
      labels: {
        type: [String, Boolean],
        default: 'start', // start | end
      },
      position: {
        type: [String],
        default: 'start', // start | end
      },
      grid: {
        type: Boolean,
        default: true,
      },
      full: {
        type: Boolean,
        default: false,
      },
      step: {
        type: Number,
        default: null,
      },
      roundness: {
        type: Number,
        default: 2,
      },
      min: {
        type: Number,
        default: 0,
      },
      gutter: {
        type: [String, Number],
        default: 20,
      },
      format: {
        type: [String, Array],
        default: '0.00',
      },
      color: {
        type: [String, Array],
        default: 'accent',
      },
      size: {
        type: [String, Number],
        default: null
      },
      unit: {
        type: String,
        default: 'h'
      },
      scroller: {
        type: String,
        default: null
      },
      maxHeight: {
        type: [String, Number],
        default: 'none'
      },
      maxWidth: {
        type: [String, Number],
        default: 'none'
      },
      minHeight: {
        type: [String, Number],
        default: 'auto'
      },
      minWidth: {
        type: [String, Number],
        default: '100%'
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      icons: {
        tip: mdiMenuDown,
      },
      hover: true,
      views: {
        chart: {
          data: [],
          width: null,
          height: null
        },
        grid: {
          steps: 0,
          step: 0,
          max: 0,
          spacing: null
        },
        labels: {
          delay: 3000,
          timer: null
        },
        tooltip: {
          toggle: false,
          target: {
            data: null,
            index: null,
          },
          value: null,
          position: null,
          color: null
        }
      }
    }),
    computed: {
      showLabels () {
        const labels = this.labels;
        let toggle = false;
        if (labels=='minimal') {
          toggle = this.hover;
          
        }else if (labels!==false) {
          toggle = labels!==false && _.some(this.data, d => {
            return d.length>1&&(_.isString(d[0])||_.isObject(d[0]));
          });
        }
        // console.log(toggle);
        return toggle;
      },

      shortLabels () {
        const data = this.views.chart.data;
        const len = _.max(_.map(data, d => d.label.length));
        // console.log(len);
        return len<=2;
      }
    },

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          this.$nextTick(() => {
            this.process(_.cloneDeep(data));
          })
        }
      },
      maxWidth () {
        this.onResize();
      },
      maxHeight () {
        this.onResize();
      },
      minWidth () {
        this.onResize();
      },
      minHeight () {
        this.onResize();
      },
    },
    
    methods: {

      process (data) {
        if (!data||_.isEmpty(data)) return;
        // const timer = this.$moment().valueOf();
        // console.log('processing chart...', timer)
        // this.controller = _.map(_.clone(data), (d, i) => {
        //   const toggle = d.highlight || _.get(this.controller[i], toggle) || false;
        //   return {
        //     toggle,
        //   }
        // });
        const isColumn = this.type=='column';
        const max = { height: this.maxHeight, width: this.maxWidth };
        const template = {
          values: [0],
          label: '',
          style: isColumn ? {
            'min-width': max.width=='none'&&this.size!=null ? this.size+'px' : 'auto',
          } : {
            'min-height': max.height=='none'&&this.size!=null ? this.size+'px' : '4px', 
          }
        };
        let tooltip = false;
        const chart = _.map(data, (d, i) => {
          let { value: label, format, pending=false, highlight=false } = _.isString(d[0]) ? { value: d[0] } : _.isObject(d[0]) ? d[0] : { value: null };
          label = !!format&&this.$moment(label).isValid() ? this.$moment(label).format(format) : null;
          const key = label || i;
          const values = !!label ? _.reject(d.slice(1, d.length), v => v===null) : d;
          const empty = _.sum(values)==0;
          const item = {
            ...template,
            key,
            label,
            values,
            rounded: _.reduce(values, (target, v, i) => target==null && v>0 ? i : target, null),
            empty,
            pending,
            highlight
          }
          if (highlight) tooltip = item;
          return item;
        });
        // console.log('processed chart', this.$moment().valueOf() - timer);
        this.views.chart.data = chart;

        this.$nextTick(() => {
          if (tooltip) {
            setTimeout(($) => {
              $.toggleTooltip(tooltip);
            }, 1000, this);
          }
          this.updateGrid(chart);
          if (this.labels=='minimal') {
              this.hover = true;
              setTimeout(($) => {
                $.toggleLabels(true, true);
              }, 500, this);
            }
        });
      },

      updateGrid (chart) {
        // console.log('processing grid...')
        const min = this.min;
        let max = _.reduce(chart, (max, bar) => {
          const total = _.sumBy(_.without(bar.values, null));
          return total > max ? total : max;
        }, 0);
        max = _.isNil(max)||min>max ? min : max;

        let step = this.step;
        const base = 2;
        const size = this.views.chart[this.type=='column' ? 'height' : 'width'];
        step = !!step ? step : !!size&&max>8 ? _.round(max / (size / 24)) : base;
        max = this.grid ? max>8 ? _.ceil(max/step)*step : 8 : max;
        console.log('step', step, max);
        
        const steps = _.map(_.times(_.ceil((max-min)/step)+1), i => {
          return i*step;
        });
        this.views.grid = { ...this.views.grid, steps, max, step };
        // console.log('processed grid')

      },

      toggleLabels (b, delay=false) {
        setTimeout(($, b) => {
          console.log('toggleLabels', b, delay)
          $.hover = b;
        }, delay ? this.views.labels.delay : 0, this, b);
      },

      toggleTooltip (data, index=null) {
        const controller = this.views.tooltip;
        controller.target.data = data;
        controller.target.index = index = !!index ? index : _.reduce(data.values, (target, v, i) => target==null && v>0 ? i : target, null);
        const ref = `bar-${data.key}-${index}`;
        const bar = this.$refs[ref][0];
        if (!!bar) {
          const top = bar.offsetTop;
          const left = bar.offsetLeft;
          const center = bar.clientWidth/2;
          const position = {
            'top': top + 'px',
            'left': (left + center) + 'px',
          }
          controller.value = data.values[index];
          controller.color = _.isArray(this.color) ? index in this.color ? this.color[index] : 'grey' : this.color;
          controller.position = position;
          controller.toggle = true;
        }
      },

      onResize () {
        this.$nextTick(() => {
          let view = _.has(this.$refs, 'view') ? this.$refs['view'] : null;
          let chart = _.has(this.$refs, 'chart') ? this.$refs['chart'] : null;
          if (!!chart) {
            view = view.getBoundingClientRect();
            chart = chart.getBoundingClientRect();
            this.views.chart.width = chart.width;
            this.views.chart.height = chart.height;
            this.views.grid.spacing = this.type=='column' ? view.height - chart.height : view.width - chart.width;
          }
        })
      }
    },

    filters: {
      formatValue (value, format, v) {
        format = _.isArray(format)&&(v in format) ? format[v] : format;
        const formatted = numeral(value).format(format);
        return formatted;
      }
    }
  }
</script>